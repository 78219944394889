import React, { useState } from "react";
import Login from "../../components/Login";
import background from "../../assets/img/background.png";
import backgroundMobile from "../../assets/img/backgroundmobile.png";
import overlay from "../../assets/img/siswa.png"; //overlay
import logo from "../../assets/img/EMANDAYA.png"; //Logo
import logoMan2 from "../../assets/img/logoman2.png"; //Logo man 2 bantul
import Carousel from "../../components/Carousel";
import Data from "../../components/Data";
import DataMobile from "../../components/Data/mobile";
import Menu from "../../components/Menu";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Index(props) {
  const appName = "Login";
  const [showLogin, setShowLogin] = useState(false);
  const deskripsi = "Selamat datang di";
  const deskripsi2 = "Belum punya akun? Silakan hubungi Admin Madrasah";
  const title2 = "EMANDAYA";
  const slogan1 = "E-Learning MAN 2 Yogyakarta";
  const copyright = "2024 E-Learning MAN 2 Yogyakarta. - Didukung oleh";
  const isMobile = window.matchMedia('(max-width: 765px)').matches;
  
  return (
    <>
      {/* {  <div style={{position:'sticky', top: 0, zIndex: 99, backgroundColor: '#fff', padding: 8, width: '100%', textAlign: 'center', fontWeight: 'bold'}}> */}
      {/* <a href="/skl/" >Pengumuman Kelulusan Siswa/i Madrasah DIY Silahkan klik disini</a> */}
      {/* </div> } */}
      <div className="col-12 hero-container">
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${background})`,
          }}
          className="hero-background col-12"
        ></div>
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})`,
          }}
          className="hero-background hero-bg-mobile col-12"
        ></div>
        <div
          className="col-12 hero"
          style={{
            backgroundPosition: "0 100%",
            backgroundRepeat: "no-repeat",

            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 81.78%, rgba(255, 255, 255, 0) 95.16%), url(${overlay})`,
          }}
        >
          <div className="">
          <div className="logoMan2">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <img
          src={logoMan2}
          alt={appName}
          style={{
            width: 370,
            height: 75,
            marginTop: 15,
            marginLeft: 15,
          }}
        />
        <img
          src={logo}
          alt={appName}
          style={{
            width: 370,
            height: 75,
            marginTop: 15,
            marginRight: 15,
            display: isMobile ? 'none' : 'block', // Media query via matchMedia
          }}
        />
      </div>
    </div>

            <div className="text-light" data-page={appName} style={{}}>
              <div
                className="menu"
                style={{ textAlign: "right", textColor: "white" }}
              ></div>
              <div className="title-mobile">
                <div className="logo">
                  <img
                    src={logo}
                    alt={appName}
                    style={{ width: 230, height: 70, marginTop: 50 }}
                  />
                </div>
                <h1
                  style={{
                    marginTop: "20px",
                    fontSize: "270%",
                    textAlign: "center",
                  }}
                >
                  {title2}
                </h1>
                <i style={{ fontSize: "200%", textAlign: "center" }}>
                  {slogan1}
                </i>

                <br></br>
                <div
                  className="login"
                  style={{
                    display: "inline-table",
                    width: "50px",
                    padding: "5px 50px 5px 50px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                    background: "#006316",
                    borderRadius: 20,
                  }}
                  onClick={() => setShowLogin(true)}
                >
                  <span
                    style={{
                      fontSize: "150%",
                      textAlign: "left",
                      fontFamily: "Poppins",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </span>
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  {deskripsi2}
                </p>
              </div>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "left",
                  paddingLeft: "15vw",
                  paddingTop: "5vw"
                }}
              >
                <div
                  className="deskripsi"
                  style={{ textAlign: "left", maxWidth: "80vw" }}
                >
                  <div className="slogan">
                    <p
                      style={{
                        fontSize: "2.5vw",
                        marginTop: "2vh",
                        fontFamily: "Poppins",
                      }}
                    >
                      {deskripsi}
                    </p>
                    <br />
                    <p
                      style={{
                        fontSize: "3vw",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      {title2}
                    </p>
                    <p
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "italic",
                      }}
                    >
                      {slogan1}
                    </p>
                    <div
                      className="login"
                      style={{
                        padding: "0 3vw",
                        cursor: "pointer",
                        background: "#006316",
                        borderRadius: "25px",
                        width: "15vw",
                        height: "5vh",
                        marginTop: "2vh",
                      }}
                      onClick={() => setShowLogin(true)}
                    >
                      <p
                        style={{
                          fontSize: "1vw",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          color: "#fff",
                          fontWeight: "bold",
                          margin: 0,
                          lineHeight: "5vh",
                        }}
                      >
                        Login
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {deskripsi2}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showLogin && (
            <div
              onClick={(e) => setShowLogin(false)}
              style={{
                position: "fixed",
                backdropFilter: "blur(4px)",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ maxWidth: "600px", width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Login />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
